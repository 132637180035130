<template>
	<div class="chat">
		<div v-if="plugin == 'normal'" class="banner-chat">
			<div>
				<h3 class="mb-1">{{ item.titolo }}</h3>
				<p><b-icon-clock-history /> {{ formatDate(item.data) }}</p>
			</div>

			<div class="ml-auto">
				<span v-if="item.id_status == 2" class="ml-auto"> Ticket Closed </span>
				<b-button v-else class="ml-auto" @click="closeTicket"
					>Mark as solved</b-button
				>
			</div>
		</div>
		<div v-else-if="plugin == 'salesChat'" class="banner-chat">
			<div>
				<h5 class="mb-1">{{ item.numeroCommessa }} - {{ item.titolo }}</h5>
				<p style="font-weight: bold">
					{{ item.product_text }}
				</p>

				<div class="d-flex justify-content-between">
					<div class="banner-chat-input-group">
						<div class="banner-chat-input-group-flex">
							<div class="pr-1">
								<label for="vendite">Valore Margine</label>
								<b-input-group size="sm" prepend="€">
									<b-form-input
										id="vendite"
										type="number"
										v-model="kpiValue"
									></b-form-input>
								</b-input-group>
							</div>
							<div v-if="margineType === 'euro'" class="pl-1">
								<label for="margine">Valore Provvigione</label>
								<b-input-group size="sm" prepend="€">
									<b-form-input
										id="margine"
										type="number"
										v-model="margineValue"
									></b-form-input>
								</b-input-group>
							</div>
							<div v-else-if="margineType == 'perc'" class="pl-1">
								<label v-if="margineValue == 0" for="margine"
									>Provvigione suggerita</label
								>
								<label v-else for="margine">Margine</label>
								<b-input-group size="sm" prepend="€">
									<b-form-input
										id="margine"
										type="number"
										:placeholder="computed_margineValue.toString()"
										v-model="margineValue"
									></b-form-input>
								</b-input-group>
							</div>
							<div v-else class="pl-1">
								<label for="margine">Valore Provvigione</label>
								<b-input-group size="sm" prepend="€">
									<b-form-input
										id="margine"
										type="number"
										v-model="margineValue"
									></b-form-input>
								</b-input-group>
							</div>
						</div>
					</div>

					<div class="d-flex align-items-end justify-content-center px-3">
						<span
							v-if="item.id_status == 3"
							style="color: green; font-weight: bold"
						>
							Ordine confermato
						</span>
						<b-button
							v-else
							@click="closeTicketSalesChat"
							:disabled="item.solved || margineValue == ''"
							>Conferma ordine
						</b-button>
					</div>
				</div>
				<div class="mt-3">
					<p><b-icon-clock-history /> {{ formatDate(item.data) }}</p>
				</div>
			</div>
		</div>

		<div class="messages-wrapper">
			<div
				v-for="message in messages"
				:key="message.id"
				class="message"
				:class="message.userType == 'B' ? 'my-message' : 'others-message'"
			>
				{{ message.message }}
				<div v-for="file in message.files" :key="file.file_url">
					<template v-if="file.file_url.match(/.(jpg|jpeg|png|gif)$/i)">
						<img style="width: 75%; border-radius: 15px" :src="file.file_url" />
					</template>
					<template v-else
						><a :href="file.file_url" target="_blank">{{
							file.file_url
						}}</a></template
					>
				</div>
				<div class="datetime">
					{{ formatDate(message.data) }}
					<b-icon-check-all
						v-if="message.is_read && message.userType == 'B'"
						class="is-read-true"
					/>
					<b-icon-check
						v-else-if="!message.is_read && message.userType == 'B'"
						class="is-read-false"
					/>
				</div>
			</div>
		</div>

		<div class="current-message d-flex">
			<b-form-textarea
				id="textarea-default"
				placeholder="Type your message..."
				no-resize
				:disabled="isSending"
				autofocus
				v-model="value"
				@keydown.enter.prevent="sendMessage"
			></b-form-textarea>

			<div
				class="
          ml-3
          d-flex
          flex-column
          align-items-center
          justify-content-between
          py-3
          h4
          m-0
        "
			>
				<b-form-file
					class="button-file attachment"
					v-model="files"
					multiple
					:state="Boolean(files)"
					:disabled="isSending"
				>
					<template #placeholder>
						<b-icon-paperclip />
					</template>
					<template #file-name>
						<b-icon-paperclip />
						<b-badge class="attachment-counter" pill variant="primary">{{
							files.length
						}}</b-badge>
					</template>
				</b-form-file>
				<b-icon
					v-if="loadingFile"
					class="loading-file"
					icon="arrow-clockwise"
					animation="spin"
					font-scale="2"
				></b-icon>

				<b-icon-arrow-right-circle-fill
					:disabled="isSending"
					@click="sendMessage"
					class="h2 pointer m-0"
				/>
			</div>
		</div>
	</div>
</template>

<script>
	import AxiosService from "./../axiosServices/AxiosService";

	import { datetimeToString, dateJsToJSON } from "@/utils/DatetimeUtils.js";
	export default {
		components: {},
		props: {
			item: Object,
			plugin: {
				type: String,
				default: "normal",
			},
		},
		service: null,
		created() {
			this.service = new AxiosService("HelpDeskChat");
			this.kpiValue = this.item.kpiValue.toString();
		},
		mounted() {
			this.loadMessages();
			this.timer = setInterval(this.loadMessages, 15000);

			if (this.plugin == "salesChat") {
				this.service
					.readCustomEndpoint("SalesChat/GetProduct/" + this.item.product_id)
					.then((res) => {
						this.margineType = res.margine_type;
						this.marginePerc = res.margine;
					});
			}
		},
		data() {
			return {
				kpiValue: 0,
				margineType: "",
				margineValue: "",
				status: null,
				messages: [],
				value: null,
				files: [],
				fileUrl: this.$driveAddress,
				isSending: false,
				marginePerc: null,
			};
		},
		methods: {
			closeTicketSalesChat() {
				const obj = { ...this.item };

				obj.kpiValue = this.kpiValue;
				obj.margineValue = this.margineValue;
				obj.id_status = 3;
				obj.solved = true;
				obj.need_reply = false;

				try {
					let currentDatetime = new Date();
					let message = {};
					message.id = null;
					message.data = dateJsToJSON(currentDatetime);
					message.message =
						"Ordine confermato. Valore vendite: " +
						this.kpiValue +
						", valore margine: " +
						this.margineValue;
					message.userType = "B";
					message.id_user = 6;
					message.files = [];
					// this.service
					//   .putCustomEndpoint(
					//     "SalesChat/CloseOrder/" + this.item.id,
					//     ""
					//   )
					//   .then((response) => {
					//     if (response.id) {
					//       message.id = response.id;
					//       this.messages.push(message);
					//       this.scrollDown();
					//       this.value = "";
					//       this.kpiValue = 0;
					//       this.margineValue = "";
					//       this.files = [];
					//       this.$emit("update-reply");
					//     } else {
					//       this.$errorToast();
					//       return false;
					//     }
					//   })
					//   .catch(() => {
					//     this.$errorToast();
					//     return false;
					//   })
					//   .finally(() => {
					//     this.isSending = false;
					//   });
				} catch {
					this.$errorToast("Ordine non confermato");
				} finally {
					this.service
						.putCustomEndpoint("SalesChat/CloseOrder", this.item.id, obj)
						.then((res) => {
							this.$successToast("Ordine confermato");
							this.$root.$emit("bv::toggle::collapse", "sidebar-chat");
							console.log(res);
						});
				}
			},
			loadMessages() {
				let params = [{ param: "idTicket", value: this.item.id }];

				if (this.plugin == "normal") {
					this.service.read(params).then((data) => {
						if (JSON.stringify(this.messages) !== JSON.stringify(data)) {
							this.messages = data;
							this.scrollDown();
						}
					});
				} else if (this.plugin == "salesChat") {
					this.service
						.readCustomEndpoint(
							"SalesChat/GetOrdersChat?idOrder=" + this.item.id
						)
						.then((data) => {
							if (JSON.stringify(this.messages) !== JSON.stringify(data)) {
								this.messages = data;
								this.scrollDown();
							}
						});
				}
			},
			scrollDown() {
				let container = this.$el.querySelector(".messages-wrapper");
				this.$nextTick(function() {
					container.scrollTop = container.scrollHeight;
				});
			},
			sendMessage() {
				if (this.isSending) {
					return false;
				}
				if (!this.value && !this.files.length) {
					return false;
				}
				this.isSending = true;
				let currentDatetime = new Date();
				let message = {};
				message.id = null;
				message.data = dateJsToJSON(currentDatetime);
				message.message = this.value;
				message.userType = "B";
				message.id_user = 6;
				message.files = [];

				let promises = [];
				this.loadingFile = true;
				for (let i = 0; i < this.files.length; i++) {
					if (this.files[i].size > 3 * 1024 * 1024) {
						this.$errorToast("File troppo grande (3MB)");
						return false;
					}
					const formData = new FormData();
					formData.append("file", this.files[i]);
					promises.push(this.service.uploadFile(formData));
				}
				Promise.all(promises)
					.then((results) => {
						results.forEach((res) => {
							if (res) {
								//   message.files.push({ file_url: this.fileUrl + res.data });
								message.files.push({ file_url: res.data });
							}
						});
					})
					.finally(() => {
						let params = [{ param: "idTicket", value: this.item.id }];

						this.loadingFile = false;

						if (this.plugin == "normal") {
							this.service
								.create(message, params)
								.then((response) => {
									if (response.id) {
										message.id = response.id;
										this.messages.push(message);
										this.scrollDown();
										this.value = "";
										this.files = [];
										this.$emit("update-reply");
									} else {
										this.$errorToast();
										return false;
									}
								})
								.catch(() => {
									this.$errorToast();
									return false;
								})
								.finally(() => {
									this.isSending = false;
								});
						} else if (this.plugin == "salesChat") {
							this.service
								.postCustomEndpoint(
									"SalesChat/NewMessage?idOrder=" + this.item.id,
									"",
									message
								)
								.then((response) => {
									if (response.id) {
										message.id = response.id;
										this.messages.push(message);
										this.scrollDown();
										this.value = "";
										this.files = [];
										this.$emit("update-reply");
									} else {
										this.$errorToast();
										return false;
									}
								})
								.catch(() => {
									this.$errorToast();
									return false;
								})
								.finally(() => {
									this.isSending = false;
								});
						}
					});
			},
			formatDate(datetime) {
				return datetimeToString(datetime);
			},
			closeTicket() {
				this.$emit("close-ticket");
			},
		},
		computed: {
			computed_margineValue() {
				return this.kpiValue * (this.marginePerc / 100);
			},
		},
	};
</script>
